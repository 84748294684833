import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { MODAL_COMPONENTS } from 'modules/Web/constants';
import { getModal } from 'App/redux/selectors';

import './modal.scss';

const Modal = () => {
  const modal = useSelector(getModal);

  const [Component, setComponent] = useState();

  const onClose = () => {
    // onSetModal();
    window.location.hash = '#action';
  };

  const onPreventClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (modal) {
      setComponent(React.lazy(MODAL_COMPONENTS[modal.component]));
    }
  }, [modal]);

  const displayComponent = useMemo(() => !!(modal && Component), [Component, modal]);

  return (
    <div className={classNames('modal', { open: !!modal })} onClick={onClose}>
      <div className="modal-content" onClick={onPreventClick}>
        <div className="modal-close" onClick={onClose}>
          &times;
        </div>
        {displayComponent && <Component {...modal.props} onClose={onClose} />}
      </div>
    </div>
  );
};

export default Modal;
