import React from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getSpeed = (top) => {
  const speeds = [300, 500, 800];
  const dists = [500, 1000, 1500];
  const dist = Math.abs(top - $(window).scrollTop());

  for (let i = 0; i < dists.length; i++) {
    if (dist < dists[i]) {
      return speeds[i];
    }
  }

  return speeds[speeds.length - 1];
};

export const scrollTo = (hash) => {
  const target = `.section-${hash}`;
  const $target = $(target);

  if ($target.length) {
    const top = $target.get(0).offsetTop;

    $(`#${window.CONFIG.containerId}`)
      .stop()
      .animate(
        {
          scrollTop: top,
        },
        getSpeed(top),
        'swing',
      );
    /*
      .promise()
      .done(() => {
        // ReactGA.pageview(hash);
        // window.location.hash = target;
        // window.history.pushState('', '', target);
      });
      */
  }
};

const SmoothScroll = ({
  hash, className, children, delay,
}) => {
  const onClick = (e) => {
    e.preventDefault();
    setTimeout(() => {
      window.location.hash = `#${hash}`;
    }, delay);
  };

  return (
    <a
      href={`#${hash}`}
      className={classNames('smoothscroll', className)}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

SmoothScroll.propTypes = {
  hash: PropTypes.string.isRequired,
  className: PropTypes.node.isRequired,
  children: PropTypes.string.isRequired,
  delay: PropTypes.number,
};

SmoothScroll.defaultProps = {
  delay: 0,
};

export default SmoothScroll;
