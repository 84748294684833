import React from 'react';
import Loadable from 'react-loadable';

import Loading from 'components/Loading';

const loadable = loader =>
	Loadable({
		loader,
		loading: () => <Loading open />,
	});

export default loadable;
