import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './waypoint.scss';

require('waypoints/lib/noframework.waypoints.min');

const Waypoint = ({ children, offset, className, handler }) => {
	const waypoint = useRef();
	const [animate, setAnimate] = useState(false);

	useEffect(
		() => {
			new window.Waypoint({
				element: waypoint.current,
				handler(direction) {
					setAnimate(direction === 'down');
					handler && handler(direction);
				},
				offset,
				context: document.getElementById(window.CONFIG.containerId),
			});
		},
		[handler, offset]
	);

	return (
		<div ref={waypoint} className={classNames('waypoint', className, { animate })}>
			{children}
		</div>
	);
};

Waypoint.propTypes = {
	children: PropTypes.node.isRequired,
	offset: PropTypes.string,
	className: PropTypes.string,
	handler: PropTypes.func,
};

Waypoint.defaultProps = {
	offset: '70%',
	className: '',
	handler: null,
};

export default Waypoint;
