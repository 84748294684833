import axios from 'axios';

axios.interceptors.request.use(
  (config) => {
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
    return config;
  },
  (error) => Promise.reject(error),
);
