import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getMenuOpen } from 'App/redux/selectors';
import { setMenuOpen } from 'App/redux/actions';
import useActions from 'hooks/useActions';

export default () => {
  const menuOpen = useSelector(getMenuOpen);
  const onSetMenuOpen = useActions(setMenuOpen);

  const onOpen = () => {
    onSetMenuOpen(true);
  };

  return (
    <div className={classNames('menu-trigger', { open: menuOpen })} onClick={onOpen}>
      <span>
        <i className="fas fa-bars" />
      </span>
    </div>
  );
};
