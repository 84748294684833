import React from 'react';
import Section, { Title } from 'components/Section';
import Waypoint from 'components/Waypoint';
import { useTranslation } from 'react-i18next';

import './services.scss';

export const Service = ({ title, children }) => (
  <article className="service">
    <div className="service-content">
      <h3 className="service-title">{title}</h3>
      <div className="service-text">{children}</div>
    </div>
  </article>
);

const Services = () => {
  const { t } = useTranslation();
  return (
    <Section className="section-services">
      <Waypoint>
        <Title transKey="services.pageTitle" />
        <div className="lead list animated services-list">
          <div className="services-container">
            <Service title={t('services.breakfast.title')}>
              <p>{t('services.breakfast.p1')}</p>
              <p className="service-price">{t('services.breakfast.p2')}</p>
            </Service>
            <Service title={t('services.picnic.title')}>
              <p>{t('services.picnic.p1')}</p>
              <p className="service-price">{t('services.picnic.p2')}</p>
            </Service>
            <Service title={t('services.dinner.title')}>
              <p>{t('services.dinner.p1')}</p>
              <p className="service-price">{t('services.dinner.p2')}</p>
            </Service>
          </div>
        </div>
      </Waypoint>
    </Section>
  );
};

export default Services;
