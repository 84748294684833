const deg2rad = (deg) => deg * (Math.PI / 180);

export const getDistance = (location1, location2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(location2.latitude - location1.latitude); // deg2rad below
  const dLon = deg2rad(location2.longitude - location1.longitude);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(location1.latitude))
      * Math.cos(deg2rad(location2.latitude))
      * Math.sin(dLon / 2)
      * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return Math.round(d * 100) / 100;
};

export const getImgsList = (prefix, number) => new Array(number).fill('').map((item, index) => `${prefix}/${prefix}_${index + 1}.jpg`);
