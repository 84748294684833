import { createTypes } from 'store/utils';

export const KEY = 'web';

export const RESULT = 'result';
export const SET_RESULT = 'SET_RESULT';

export const GEOLOCATION = 'geolocation';
export const SET_GEOLOCATION = 'SET_GEOLOCATION';

export const HASH = 'hash';
export const SET_HASH = 'SET_HASH';

export const MyTypesArray = [SET_RESULT, SET_GEOLOCATION, SET_HASH];
export const TYPES = createTypes(KEY, MyTypesArray);
