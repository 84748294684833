import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Formatter } from 'i18n';
import Block from './Block';

import './section.scss';

export const Title = ({ transKey }) => (
  <h1 className="title animated">
    <Formatter transKey={transKey}>
      1
      <span>2</span>
    </Formatter>
  </h1>
);

const Section = ({
  children, className, style,
}) => (
  <section className={classNames('section', className)} style={style}>
    <div className="overlay">{children}</div>
  </section>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf({}),
};

Section.defaultProps = {
  className: undefined,
  style: undefined,
};

export { Block };

export default Section;
