import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Section from 'components/Section';
import Waypoint from 'components/Waypoint';
import classNames from 'classnames';

import './home.scss';

const HashLink = ({
  hash, icon, resource, className, ...props
}) => {
  const { t } = useTranslation();
  const onClick = () => {
    document.location.hash = `#${hash}`;
  };

  const classes = useMemo(() => classNames('link home-link', className), [className]);
  const text = useMemo(() => t(resource), [resource, t]);

  return (
    <button
      {...props}
      type="button"
      className={classes}
      onClick={onClick}
    >
      <i className={icon} />
      {text}
    </button>
  );
};

HashLink.defaultProps = {
  className: '',
};


const links = [
  {
    hash: 'gites',
    icon: 'fas fa-home',
    resource: 'gites.title',
  },
  {
    hash: 'insolite',
    icon: 'fas fa-campground',
    resource: 'insolite.title',
  },
  {
    hash: 'camping',
    icon: 'fas fa-caravan',
    resource: 'camping.title',
  },
  {
    hash: 'velo',
    icon: 'fa-solid fa-person-biking',
    resource: 'velo.title',
  },
  {
    hash: 'services',
    icon: 'fas fa-coffee',
    resource: 'services.title',
  },
  {
    hash: 'decouvrir',
    icon: 'fab fa-pagelines',
    resource: 'discover.title',
  },
];


const Home = () => {
  const { t } = useTranslation();

  const logoUrl = useMemo(() => `${window.CONFIG.BASE_URL}/images/logo.svg`, []);

  return (
    <Section className="section-home">
      <Waypoint className="home-content">
        <div className="home-header">
          <div className="home-title">
            <img className="home-title__img" src={logoUrl} alt="Gitavesnois" />
            {t('home.title')}
          </div>
          <div className="home-subtitle">{t('home.description')}</div>
        </div>
        <div className="home-img" />
        <div className="home-menu">
          {links.map((link) => <HashLink {...link} />)}
        </div>
      </Waypoint>
    </Section>
  );
};

export default Home;
