import { createReducer, assignPayloadToKey } from 'store/utils';
import {
  TYPES,
  SET_RESULT,
  RESULT,
  SET_GEOLOCATION,
  GEOLOCATION,
  SET_HASH,
  HASH,
} from './constants';

const defaultState = {};

const behaviors = {
  [TYPES[SET_RESULT]]: assignPayloadToKey(RESULT),
  [TYPES[SET_GEOLOCATION]]: assignPayloadToKey(GEOLOCATION),
  [TYPES[SET_HASH]]: assignPayloadToKey(HASH),
};

export default createReducer(behaviors, defaultState);
