import React from 'react';
import Section, { Block, Title } from 'components/Section';
import Waypoint from 'components/Waypoint';
import { useTranslation } from 'react-i18next';
import { Formatter } from 'i18n';
import { getImgsList } from '../../../../utils';

import './velo.scss';

const veloImgs = getImgsList('velo', 4);

const Velo = () => {
  const { t } = useTranslation();
  return (
    <Section className="section-velo">
      <Waypoint>
        <Title transKey="velo.pageTitle" />
        <div className="lead list animated">
          <Block imgs={veloImgs}>
            <p>
              <Formatter transKey="velo.velo1.p1">
                1
                <span className="highlight">1</span>
              </Formatter>
            </p>
            <p>{t('velo.velo1.p2')}</p>
          </Block>
        </div>
      </Waypoint>
    </Section>
  );
};

export default Velo;
