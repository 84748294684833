import loadable from 'HOC/loadable';
import { createBrowserHistory } from 'history';

import Web from 'modules/Web';

const { BASE_URL } = window.CONFIG;

// const Web = loadable(() => import('modules/Web'));

const Error404 = loadable(() => import('modules/Errors/404'));

export const history = createBrowserHistory();

export default [
  {
    path: `${BASE_URL}/`,
    exact: true,
    name: 'Web',
    component: Web,
  },
  { name: '404', component: Error404 },
];
