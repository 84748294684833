import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SmoothScroll from 'components/SmoothScroll';

const Item = ({
  isCurrent, hash, name, icon, onClick,
}) => (
  <li className={classNames('item', { current: isCurrent })} onClick={onClick}>
    <SmoothScroll hash={hash} delay={200}>
      <i className={classNames('icon', icon)} />
      <span className="name">{name}</span>
    </SmoothScroll>
  </li>
);

Item.propTypes = {
  isCurrent: PropTypes.bool,
  hash: PropTypes.string.isRequired,
  name: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

Item.defaultProps = {
  isCurrent: false,
  name: null,
};

export default Item;
