import React from 'react';

import './footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="credits">© 2021 Bonneau</div>
    <div className="version">
      <span>
v
        {window.CONFIG.VERSION}
      </span>
    </div>
  </footer>
);

export default Footer;
