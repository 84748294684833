import { createReducer, assignPayloadToKey } from 'store/utils';
import {
  TYPES,
  LOADING,
  SET_LANG,
  LANG,
  SET_MENU_OPEN,
  NOTIFICATION,
  SET_NOTIFICATION,
  START_LOADING,
  STOP_LOADING,
  MENU_OPEN,
  MODAL,
  SET_MODAL,
} from './constants';

const defaultState = {
  [LANG]: null,
  [LOADING]: {},
  [MENU_OPEN]: false,
};

const behaviors = {
  [TYPES[START_LOADING]]: (state, { payload }) => ({
    ...state,
    [LOADING]: { ...state[LOADING], [payload]: true },
  }),
  [TYPES[STOP_LOADING]]: (state, { payload }) => ({
    ...state,
    [LOADING]: { ...state[LOADING], [payload]: false },
  }),
  [TYPES[SET_LANG]]: assignPayloadToKey(LANG),
  [TYPES[SET_MENU_OPEN]]: assignPayloadToKey(MENU_OPEN),
  [TYPES[SET_NOTIFICATION]]: assignPayloadToKey(NOTIFICATION),
  [TYPES[SET_MODAL]]: assignPayloadToKey(MODAL),
};

export default createReducer(behaviors, defaultState);
