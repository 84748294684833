import React from 'react';
import { useTranslation } from 'react-i18next';
import '../booking.scss';

const BookingLink = ({
  url,
}) => {
  const { t } = useTranslation();

  return (
    <a href={url} className="booking-link" target="_blank" rel="noopener noreferrer">{t('booking.book')}</a>
  );
};

export default BookingLink;
