import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

export default function RoutesBuilder({ routes }) {
  return (
    <Switch>
      {routes.map((route) => (route.component ? (
        <Route
          key={route.name}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null))}
    </Switch>
  );
}

RoutesBuilder.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};
