import React from 'react';
import Section, { Block, Title } from 'components/Section';
import Waypoint from 'components/Waypoint';
import { useTranslation } from 'react-i18next';

import './gites.scss';
import Booking from 'components/Booking';
import BookingLink from 'components/Booking/BookingLink';

import { getImgsList } from '../../../../utils';

const bookingLink1 = 'https://tinyurl.com/26t8ob6z';
const bookingLink2 = 'https://tinyurl.com/2fhxaqjv';

// https://iteaburo.elloha.com/d699b4b4-4185-4a7a-91e2-321c3669f190/7f2c0ccd-f0ce-4077-8fae-0489864b6733.ics

const gite2Imgs = getImgsList('gite_2', 12);
const gite4Imgs = getImgsList('gite_4', 10);

const Gites = () => {
  const { t } = useTranslation();
  return (
    <Section className="section-gites">
      <Waypoint>
        <Title transKey="gites.pageTitle" />
        <div className="lead list animated">
          <Block title={t('gites.gite1.title')} imgs={gite2Imgs}>
            <p>{t('gites.gite1.p1')}</p>
            <p>{t('gites.gite1.p2')}</p>
            <Booking what="gite4910" />
            <BookingLink url={bookingLink1} />
          </Block>
          <Block title={t('gites.gite2.title')} imgs={gite4Imgs}>
            <p>{t('gites.gite2.p1')}</p>
            <p>{t('gites.gite2.p2')}</p>
            <p>{t('gites.gite2.p3')}</p>
            <Booking what="gite2" />
            <BookingLink url={bookingLink2} />
          </Block>
        </div>
      </Waypoint>
    </Section>
  );
};

export default Gites;
