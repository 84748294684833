import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getHash } from 'modules/Web/redux/selectors';
import { setHash } from 'modules/Web/redux/actions';
import ReactGA from 'react-ga';
import useActions from './useActions';

const useHash = () => {
  const hash = useSelector(getHash);
  const onSetHash = useActions(setHash);

  useEffect(() => {
    const listener = () => {
      const newHash = window.location.hash;
      onSetHash(newHash);
      ReactGA.pageview(newHash);
    };
    window.onhashchange = listener;
    listener();
    return () => {
      window.onhashchange = undefined;
    };
  }, [onSetHash]);

  return hash;
};

export default useHash;
