import React, { useMemo } from 'react';
import classNames from 'classnames';


import './section.scss';
import Diapo from '../Diapo';

export const Block = ({
  title, children, imgs, className,
}) => {
  const classes = useMemo(() => classNames('section-block', className), [className]);

  return (
    <div className={classes}>
      {title && <h2 className="section-block-title">{title}</h2>}
      {imgs && <Diapo imgs={imgs} />}
      <article className="section-block-text">
        {children}
      </article>
    </div>
  );
};

Block.defaultProps = {
  imgs: null,
};

export default Block;
