import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import './booking.scss';

const { BASE_URL } = window.CONFIG;

const useBookingHeight = (what) => {
  const [bookingHeight, setBookingHeight] = useState(0);

  const handleMessage = useCallback((raw) => {
    try {
      const message = JSON.parse(raw.data);
      if (message.type && message.type === 'reservationHeightChange' && what === message.what) {
        setBookingHeight(message.height);
      }
    } catch (e) {
      console.error('Could not parse message', raw.data, e);
    }
  }, [what]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, [handleMessage]);

  return bookingHeight;
};

const Booking = ({
  what,
}) => {
  const { t } = useTranslation();
  const bookingHeight = useBookingHeight(what);
  const src = useMemo(() => `${BASE_URL}/bookings/${what}.html`, [what]);
  const style = useMemo(() => ({ height: bookingHeight }), [bookingHeight]);

  return (
    <div className="booking">
      <h3 className="booking__title">{t('booking.title')}</h3>
      <iframe className="booking__iframe" title={what} src={src} style={style} />
    </div>
  );
};

export default Booking;
