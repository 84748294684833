import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Waypoint from 'components/Waypoint';
import Section, { Block, Title } from 'components/Section';
import Axios from 'axios';

import './contact.scss';

const mock = () => new Promise((resolve) => {
  setTimeout(() => {
    resolve(true);
  }, 5000);
});

const STATUS = {
  IDLE: 'IDLE',
  SENDING: 'SENDING',
  SENT: 'SENT',
};

const Contact = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [sendingStatus, setSendingStatus] = useState(STATUS.IDLE);

  const fields = { email: setEmail, message: setMessage };

  const onSubmit = async (e) => {
    if (email && message) {
      setLoading(true);
      const params = new URLSearchParams();
      params.append('email', email);
      params.append('message', message);

      await Axios.post(window.CONFIG.MAIL_URL, params);

      // await mock();

      setSendingStatus(STATUS.SENT);
    }
  };

  const onChange = (e) => {
    fields[e.target.name](e.target.value);
  };

  const disabled = !(email.trim() && message.trim());
  const {
    CONTACT: {
      mail, number, displayedNumber, mapUrl,
    },
  } = window.CONFIG;

  const submitClasses = useMemo(() => classNames('submit', {
    'submit-loading': loading,
    sent: sendingStatus === STATUS.SENT,
    disabled,
  }), [disabled, loading, sendingStatus]);

  return (
    <Section className="section-contact">
      <Waypoint>
        <Title transKey="contact.title" />
        <div className="lead list">
          <div className="contact__blocks">
            <Block title={t('contact.details')} className="contact__details">
              <a className="contact__link" href={`mailto:${mail}`}>
                <i className="far fa-envelope-open" />
                {mail}
              </a>
              <a className="contact__link" href={`tel:${number}`}>
                <i className="fas fa-mobile-alt" />
                {' '}
                {displayedNumber}
              </a>
            </Block>
            <Block title={t('contact.message')}>
              <div className="form">
                <div className="field">
                  <label>
                    {t('contact.email')}
                    {' '}
*
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="input email"
                    value={email}
                    onChange={onChange}
                  />
                </div>

                <div className="field">
                  <label>
                    {t('contact.messageContent')}
                    {' '}
*
                  </label>
                  <textarea
                    name="message"
                    className="input textarea message"
                    value={message}
                    onChange={onChange}
                  >
                    {message}
                  </textarea>
                </div>

                <div className={submitClasses}>
                  <button className="button" type="button" onClick={onSubmit}>{t('contact.send')}</button>
                  <div className="waiting">{t('contact.sending')}</div>
                  {sendingStatus === STATUS.SENT && <div className="done">{t('contact.sent')}</div>}
                </div>
              </div>
            </Block>
          </div>
          <Block title={t('contact.map')}>
            <div className="contact__map">
              <iframe
                src={mapUrl}
                width="100%"
                height="100%"
                frameBorder="0"
                title="Google map"
                allowFullScreen
              />
            </div>
          </Block>
        </div>
      </Waypoint>
    </Section>
  );
};

export default Contact;
