import { createAction } from 'store/utils';
import {
  TYPES,
  START_LOADING,
  STOP_LOADING,
  SET_LANG,
  SET_MENU_OPEN,
  SET_NOTIFICATION,
  SET_MODAL,
} from './constants';

export const startLoading = createAction(TYPES[START_LOADING]);
export const stopLoading = createAction(TYPES[STOP_LOADING]);
export const setLang = createAction(TYPES[SET_LANG]);
export const setMenuOpen = createAction(TYPES[SET_MENU_OPEN]);
export const setModal = createAction(TYPES[SET_MODAL]);

export const setNotification = createAction(TYPES[SET_NOTIFICATION]);
