import React, {
  useMemo, useState, useEffect, useRef, useCallback,
} from 'react';
import classNames from 'classnames';

import './diapo.scss';

const { BASE_URL } = window.CONFIG;

const DiapoImg = ({ img, width }) => {
  const style = useMemo(() => ({ width, backgroundImage: `url("${BASE_URL}/images/${img}")` }), [img, width]);

  return (
    <div
      className="diapo__img"
      style={style}
    />
  );
};


function useWindowSize(handleResize) {
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
}

const Diapo = ({
  imgs, className,
}) => {
  const dom = useRef();
  // const [contentStyle, setContentStyle] = useState({});
  //
  const [currentWidth, setCurrentWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [enlarged, setEnlarged] = useState(false);

  const classes = useMemo(() => classNames('diapo', className, { 'diapo--enlarged': enlarged }), [className, enlarged]);

  const iconClass = useMemo(() => (enlarged ? 'fas fa-compress' : 'fas fa-expand'), [enlarged]);

  const updateWidth = useCallback(() => {
    setCurrentWidth(dom.current.offsetWidth);
  }, []);

  useWindowSize(updateWidth);

  useEffect(() => {
    if (dom.current) {
      updateWidth();
    }
  }, [updateWidth]);

  const contentStyle = useMemo(() => ({
    width: currentWidth,
    left: -currentIndex * currentWidth,
  }), [currentIndex, currentWidth]);

  const left = () => {
    setEnlarged(true);
    setCurrentIndex(currentIndex - 1);
  };

  const right = () => {
    setEnlarged(true);
    setCurrentIndex(currentIndex + 1);
  };

  const enlarge = () => {
    setEnlarged(!enlarged);
  };

  return (
    <div className={classes} ref={dom}>
      <button type="button" className="diapo__enlarge" onClick={enlarge}>
        <i className={iconClass} />
      </button>
      { imgs.length > 1 && (
      <>
        <button type="button" className="diapo__nav" onClick={left} disabled={currentIndex === 0}>
          <i className="fas fa-arrow-left" />
        </button>
        <button type="button" className="diapo__nav" onClick={right} disabled={currentIndex === imgs.length - 1}>
          <i className="fas fa-arrow-right" />
        </button>
      </>
      )}
      <div className="diapo__content" style={contentStyle}>
        {imgs.map((img) => <DiapoImg key={img} img={img} width={currentWidth} />)}
      </div>
    </div>
  );
};

export default Diapo;
