import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getLoading } from '../../App/redux/selectors';

import './loading.scss';

const Loading = ({ id }) => {
  const loading = useSelector(getLoading);

  return (
    <div className={classNames('loading', { open: loading[id] })}>
      <i className="fas fa-spinner" />
    </div>
  );
};

export default Loading;
