import React from 'react';
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next, useTranslation, Trans } from 'react-i18next';

const { BASE_URL } = window.CONFIG;

class LangManager {
  constructor() {
    i18n
      // load translation using xhr -> see /public/locales
      // learn more: https://github.com/i18next/i18next-xhr-backend
      .use(Backend)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        backend: {
          loadPath: `${BASE_URL}/locales/{{lng}}/{{ns}}.json`,
        },
        fallbackLng: 'fr',
        debug: true,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      });
  }

  getLang() {
    return localStorage.getItem('lang') || window.CONFIG.defaultLang;
  }

  setLang(lang) {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
  }

  getAvailableLangs() {
    return ['en', 'fr'];
  }
}

export const Formatter = ({ transKey, children }) => {
  const { t } = useTranslation();
  return (
    <Trans t={t} i18nKey={transKey}>
      {children}
    </Trans>
  );
};

export default new LangManager();
