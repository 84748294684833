import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Section, { Block } from 'components/Section';
import Waypoint from 'components/Waypoint';

import './about.scss';

const About = () => {
  const { t } = useTranslation();

  return (
    <Section className="section-a-propos">
      <Waypoint className="about__content">
        <Block title="" imgs={['about.jpg']}>
          <p>
            <Trans i18nKey="home.summary">
              1
              <span className="first">2</span>
            </Trans>
          </p>
          <p>{t('home.summary2')}</p>
          <p>{t('home.summary3')}</p>
          <div className="about__links">
            <a className="about__booking" href="#contact">
              <div className="about__booking-logo">
                <i className="fas fa-info" />
              </div>
              <div className="about__booking-text">{t('contact.title')}</div>
            </a>
            <a
              href="https://www.facebook.com/GiteValJoly?ref=hl"
              className="about__social"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook" />
              <div className="about__social-text">{t('home.facebook')}</div>
            </a>
            <a
              href="https://www.instagram.com/gitavesnois/?hl=fr"
              className="about__social"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram" />
              <div className="about__social-text">{t('home.instagram')}</div>
            </a>
          </div>
        </Block>
      </Waypoint>
    </Section>
  );
};

export default About;
