import React from 'react';
import Section, { Block, Title } from 'components/Section';
import Waypoint from 'components/Waypoint';
import { useTranslation } from 'react-i18next';
import { Formatter } from 'i18n';
import Booking from 'components/Booking';
import BookingLink from '../../../../components/Booking/BookingLink';
import { getImgsList } from '../../../../utils';

import './insolite.scss';

const bivouacImgs = getImgsList('bivouac', 6);
const caravaneImgs = getImgsList('caravane', 5);
const lodgeImgs = getImgsList('lodge', 4);
const yourteImgs = getImgsList('yourte', 6);

const Insolite = () => {
  const { t } = useTranslation();
  return (
    <Section className="section-insolite">
      <Waypoint>
        <Title transKey="insolite.pageTitle" />
        <div className="lead list animated">
          <Block title={t('insolite.bivouac.title')} imgs={bivouacImgs}>
            <p>
              <Formatter transKey="insolite.bivouac.p1">
                1
                <span className="highlight">1</span>
              </Formatter>
            </p>
            <p>{t('insolite.bivouac.p2')}</p>
            <p>{t('insolite.bivouac.p3')}</p>
            <p>{t('insolite.bivouac.p4')}</p>
            <ul>
              <li>{`• ${t('insolite.bivouac.list.l1')}`}</li>
              <li>{`• ${t('insolite.bivouac.list.l2')}`}</li>
              <li>{`• ${t('insolite.bivouac.list.l3')}`}</li>
            </ul>
            <Booking what="bivouac" />
            <BookingLink url="https://reservation.elloha.com/?IdPublication=04057c11-ab88-425a-bcc6-0f8f3b0c307e&culture=&searchFirstAvailableDates=1" />
          </Block>

          <Block title={t('insolite.lodge.title')} imgs={lodgeImgs}>
            <p>
              <Formatter transKey="insolite.lodge.p1">
                1
                <span className="highlight">1</span>
              </Formatter>
            </p>
            <p>{t('insolite.lodge.p2')}</p>
            <p>{t('insolite.lodge.p3')}</p>
            <p>{t('insolite.lodge.p4')}</p>
            <p>{t('insolite.lodge.p5')}</p>
            <ul>
              <li>{`• ${t('insolite.lodge.list.l1')}`}</li>
              <li>{`• ${t('insolite.lodge.list.l2')}`}</li>
              <li>{`• ${t('insolite.lodge.list.l3')}`}</li>
            </ul>
            <Booking what="lodge" />
            <BookingLink url="https://reservation.elloha.com/?IdPublication=e0b878a3-8860-4c07-aa51-ed06c3c38f78&culture=&searchFirstAvailableDates=1" />
          </Block>

          <Block title={t('insolite.insolite3.title')} imgs={yourteImgs}>
            <p>
              <Formatter transKey="insolite.insolite3.p1">
                1
                <span className="highlight">1</span>
              </Formatter>
            </p>
            <p>{t('insolite.insolite3.p2')}</p>
            <p>{t('insolite.insolite3.p3')}</p>
            <p>{t('insolite.insolite3.p4')}</p>
            <p>{t('insolite.insolite3.p5')}</p>
            <ul>
              <li>{`• ${t('insolite.insolite3.list.l1')}`}</li>
              <li>{`• ${t('insolite.insolite3.list.l2')}`}</li>
              <li>{`• ${t('insolite.insolite3.list.l3')}`}</li>
            </ul>
            <Booking what="yourte" />
            <BookingLink url="https://reservation.elloha.com/?IdPublication=c19199c3-5d1d-47f9-8b6b-e7404c503f87&culture=&searchFirstAvailableDates=1" />
          </Block>

          <Block title={t('camping.vintage.title')} imgs={caravaneImgs}>
            <p>
              <Formatter transKey="camping.vintage.p1">
                1
                <span className="highlight">1</span>
              </Formatter>
            </p>

            <p>{t('camping.vintage.p2')}</p>
            <p>{t('camping.vintage.p3')}</p>
            <p>{t('camping.vintage.p4')}</p>
            <p>{t('camping.vintage.p5')}</p>
            <ul>
              <li>{`• ${t('camping.vintage.list.l1')}`}</li>
              <li>{`• ${t('camping.vintage.list.l2')}`}</li>
              <li>{`• ${t('camping.vintage.list.l3')}`}</li>
            </ul>
            <Booking what="caravane" />
            <BookingLink url="https://reservation.elloha.com/?IdPublication=a63c29c7-e827-4d21-a593-01ba869a52ce&culture=&searchFirstAvailableDates=1" />
          </Block>
        </div>
      </Waypoint>
    </Section>
  );
};

export default Insolite;
