import React from 'react';
import Section, { Block, Title } from 'components/Section';
import Waypoint from 'components/Waypoint';
import { useTranslation } from 'react-i18next';
import { Formatter } from 'i18n';

import Booking from 'components/Booking';
import BookingLink from 'components/Booking/BookingLink';
import { getImgsList } from '../../../../utils';

import './camping.scss';

const bookingLink = 'https://tinyurl.com/27p429z7';

const campingImgs = getImgsList('camping', 6);

const Camping = () => {
  const { t } = useTranslation();
  return (
    <Section className="section-camping">
      <Waypoint>
        <Title transKey="camping.pageTitle" />
        <div className="lead list animated">
          <Block title={t('camping.camping1.title')} imgs={campingImgs}>
            <p>
              <Formatter transKey="camping.camping1.p1">
                1
                <span className="highlight">1</span>
              </Formatter>
            </p>
            <p>{t('camping.camping1.p2')}</p>
            <ul>
              <li>{`• ${t('camping.camping1.list.l1')}`}</li>
              <li>{`• ${t('camping.camping1.list.l2')}`}</li>
            </ul>
            <Booking what="camping" />
            <BookingLink url={bookingLink} />
          </Block>
        </div>
      </Waypoint>
    </Section>
  );
};

export default Camping;
