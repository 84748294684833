import React, { Suspense } from 'react';

import store, { persistor } from 'store';
import { Provider } from 'react-redux';
import A2HSProvider from 'a2hs';
import { PersistGate } from 'redux-persist/integration/react';

import Loader from 'components/Loader';

import { Router, Route, Switch } from 'react-router-dom';
import routes, { history } from 'routes';
import RoutesBuilder from 'components/RoutesBuilder';

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <A2HSProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <Switch>
                <Route>
                  <RoutesBuilder routes={routes} />
                </Route>
              </Switch>
            </Router>
          </PersistGate>
        </Provider>
      </A2HSProvider>
    </Suspense>
  );
}

export default App;
