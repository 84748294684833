import { createTypes } from 'store/utils';

export const KEY = 'application';

export const LOADING = 'loading';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const LANG = 'lang';
export const SET_LANG = 'SET_LANG';

export const MENU_OPEN = 'menuOpen';
export const SET_MENU_OPEN = 'SET_MENU_OPEN';

export const NOTIFICATION = 'notification';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

export const MODAL = 'modal';
export const SET_MODAL = 'SET_MODAL';

export const MyTypesArray = [
  START_LOADING,
  STOP_LOADING,
  SET_LANG,
  SET_MENU_OPEN,
  SET_NOTIFICATION,
  SET_MODAL,
];
export const TYPES = createTypes(KEY, MyTypesArray);
