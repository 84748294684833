import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getMenuOpen } from 'App/redux/selectors';
import useActions from 'hooks/useActions';
import { setMenuOpen } from 'App/redux/actions';
import Item from './Item';

import './menu.scss';

const Menu = () => {
  const { t } = useTranslation();

  const menuOpen = useSelector(getMenuOpen);
  const onSetMenuOpen = useActions(setMenuOpen);

  const items = useMemo(
    () => [
      {
        hash: 'home',
        name: t('home.menuTitle'),
        icon: 'fas fa-portrait',
      },
      {
        hash: 'gites',
        name: t('gites.title'),
        icon: 'fas fa-home',
      },
      {
        hash: 'insolite',
        name: t('insolite.title'),
        icon: 'fas fa-campground',
      },
      {
        hash: 'camping',
        name: t('camping.title'),
        icon: 'fas fa-caravan',
      },
      {
        hash: 'velo',
        name: t('velo.title'),
        icon: "fa-solid fa-person-biking",
      },
      {
        hash: 'services',
        name: t('services.title'),
        icon: 'fas fa-coffee',
      },
      {
        hash: 'decouvrir',
        name: t('discover.title'),
        icon: 'fab fa-pagelines',
      },
      {
        hash: 'contact',
        name: t('contact.title'),
        icon: 'fab fa-pagelines',
      },
    ],
    [t],
  );

  const onClick = () => {
    onSetMenuOpen(false);
  };

  return (
    <div className={classNames('st-menu menu', { open: menuOpen })} onClick={onClick}>
      <div type="button" className="menu-close">
        &times;
      </div>
      <nav>
        <ul className="menu-container navigation">
          {items.map((item) => (
            <Item key={item.hash} {...item} />
          ))}
        </ul>
        {/* <Langs /> */}
      </nav>
    </div>
  );
};

export default Menu;
